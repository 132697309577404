@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.text-left {
  margin-right: 80px;
  margin-top: 30px;
}

.text-right {
  margin-right: 60px;
  
}



@layer components {

  /* SideBar */
  .sidebar{
    @apply flex flex-col gap-y-4 h-screen bg-dark-grey p-5 pt-8 relative duration-500;
  }

  .sidebar__app-bar{
    @apply flex justify-around items-center;
  }

  .chatIcon{
  /* put these icons to the end of line  */
    @apply flex flex-col justify-end items-end;

  }
  
  .sidebar__app-title{
    @apply text-white font-medium text-xl duration-500;
  }
  
  .sidebar__btn-close {
    @apply cursor-pointer duration-500 text-white justify-end;
  }

  .sidebar__btn-icon{
    @apply w-8 h-8;
  }

  .sidebar__app-logo{
    @apply cursor-pointer duration-500 text-white;
  }

/* SideBar Nav */
  .nav{
    @apply flex justify-around;
  }

  .nav__item {
    @apply p-4 flex h-12 items-center gap-x-4 w-screen 
    rounded-md cursor-pointer 
    hover:bg-light-white
    text-white text-base;
  }

  .nav__icons{
    @apply cursor-pointer duration-500 text-white
    text-xl;
  }

  .nav__bottom{
    @apply flex flex-col justify-end h-screen;
  }

  .nav__msg{
    @apply flex bg-yellow-900 rounded-xl p-4 text-white;
  }

  .nav__p{
    @apply font-mono;
  }


  /* CHATVIEW */
  .chatview{
    @apply h-screen flex-1 p-7 duration-500 overflow-hidden relative bg-slate-200
     

  dark:bg-light-grey;
  
  }

  .chatview__chatarea {
    @apply h-[80vh] w-full overflow-y-scroll flex flex-col m-2 shadow-md;
  }
  
  .form{
    @apply flex justify-between flex-col items-stretch sm:flex-row m-2 absolute bottom-0 left-0 right-0
    text-black dark:text-white duration-500;
  }

  .dropdown {
    @apply h-16 px-4 rounded-xl bg-transparent hover:bg-slate-200 hover:dark:bg-dark-grey border border-light-grey
    dark:border-dark-grey focus:ring-offset-indigo-200 text-black dark:text-white transition 
    ease-in-out duration-500 text-center text-base font-semibold shadow-md 
    focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .message__img {
    @apply rounded-lg shadow-lg bg-cover bg-gray-400 h-[512px] w-[512px] duration-500 ease-out;
  }

  .chatview__textarea-message{
    @apply text-xl bg-transparent h-16 sm:w-screen max-h-[20rem] min-h-[4rem] p-2 dark:bg-light-grey
    border border-light-grey dark:border-dark-grey overflow-y-hidden mx-2
    resize-y rounded-xl;
  }

  .chatview__btn-send{
    @apply h-16 px-4 rounded-xl bg-transparent hover:bg-slate-200 hover:dark:bg-dark-grey border border-light-grey
    dark:border-dark-grey focus:ring-offset-indigo-200 text-black dark:text-white transition 
    ease-in-out duration-500 text-center text-base font-semibold shadow-md 
    focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .chatview__btn-send:disabled{
    @apply hover:bg-light-grey transition ease-in duration-500 text-center text-base font-semibold shadow-md
  }


  /* ChatMessage */
  .message{
    @apply  gap-2 p-5 m-2 flex items-start justify-between;
  }

  .message__wrapper{
    @apply flex-1 w-screen flex-wrap overflow-hidden;
  }

  .message__markdown{
    @apply text-xl text-slate-700 dark:text-slate-200;
  }

  .message__createdAt{
    @apply text-sm font-thin italic text-slate-600 dark:text-slate-300;
  }

  .message__pic{
    @apply h-10 w-10 bg-slate-200 mr-4 rounded-full flex items-center justify-center text-xl;
  }

  .message__thinking{
    @apply animate-pulse duration-1000 h-12 w-12 dark:text-white text-black text-xl
  }

  /* Signin Page */

  .signin{
    @apply flex flex-col justify-center items-center h-screen w-screen overflow-hidden bg-slate-600;
  }

  .signin__btn{
    @apply h-16 px-4 rounded-xl bg-slate-100 hover:bg-slate-300 border
     border-light-grey focus:ring-offset-indigo-200 text-black transition 
    ease-in-out duration-500 text-center text-base font-semibold shadow-md 
    focus:outline-none focus:ring-2 focus:ring-offset-2;
  }
  
  .signin__tos{
    @apply text-white text-center font-bold p-2;
  }

}

::-webkit-scrollbar {
  @apply bg-transparent w-1;
}

::-webkit-scrollbar-thumb {
  @apply bg-light-grey dark:bg-dark-grey rounded-xl;
}